import NextImage from 'next/image';
import React from 'react';

import {
  PressBannerSection,
  FlexRow,
  PressBannerItem,
  PressBannerContainer,
  ImageWrapper,
} from './styles';
import { PressBannerProps } from './types';

function PressBanner({ images }: PressBannerProps) {
  return (
    <PressBannerSection>
      <PressBannerContainer noPadding>
        <FlexRow>
          {images
            ? images.map(({ _key, alt = '', cta, asset }) => {
                const hasALink = !!cta?.url;
                const wrapperProps = hasALink
                  ? {
                      target: '_blank',
                      href: cta?.url,
                      rel: 'noreferrer',
                    }
                  : {};
                return (
                  <PressBannerItem key={_key}>
                    <ImageWrapper
                      as={hasALink ? 'a' : 'span'}
                      {...wrapperProps}
                    >
                      <NextImage layout="fill" src={asset?.url} alt={alt} />
                    </ImageWrapper>
                  </PressBannerItem>
                );
              })
            : null}
        </FlexRow>
      </PressBannerContainer>
    </PressBannerSection>
  );
}

export default PressBanner;
