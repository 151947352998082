import styled, { keyframes } from 'styled-components';

import { IAnimatedDotProps, ILoadingTextContainer } from './types';

export const LoadingTextContainer = styled.div<ILoadingTextContainer>`
  display: ${(props) => (props.alwaysShow ? 'flex' : 'none')};
  color: inherit;
  align-items: center;
  justify-content: center;
`;

const bouncingDots = keyframes`
 0% {
    transform: translateY(100%);
  }

  50% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
`;

export const AnimatedDot = styled.span<IAnimatedDotProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-left: 2px;
  transform: translateY(100%);
  animation: ${bouncingDots} 1s ease-out ${(props) => `${props.delay}s`}
    infinite;

  &::after {
    content: '\u2219';
  }
`;
