import { Formik, Form } from 'formik';
import React, { useState } from 'react';

import Button from 'components/Button';
import Input from 'components/forms/Input';
import { Twitter, Instagram, LinkedIn, YouTube } from 'components/icons';
import TrustPilot from 'components/icons/Trustpilot';
import TrustPilotText from 'components/icons/TrustPilotText';
import SiteLink from 'components/Link';
import subscribeEmail from 'utils/newsletter/subscribe';

import {
  InputRow,
  DesktopButton,
  LowerRow,
  MobileButton,
  SocialIcons,
  SocialIconsWrapper,
  TrustPilotIcon,
} from '../styles';

import { InputWrapper, SubmitStateText, SuccessText } from './styles';

export default function MailingListSignup() {
  const [submitState, setSubmitState] = useState<null | 'success'>(null);

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async ({ email }, actions) => {
        try {
          await subscribeEmail({ email });
          actions.resetForm();
          setSubmitState('success');
        } catch (error) {
          // @TODO: Error handling
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <InputRow>
            <InputWrapper>
              <Input
                placeholder="Your Email"
                variant="secondary"
                type="email"
                name="email"
                autoComplete="email"
              />
              <SubmitStateText>
                {submitState === 'success' ? (
                  <SuccessText>Thanks for signing up!</SuccessText>
                ) : (
                  <>
                    By subscribing, you agree with our{' '}
                    <SiteLink
                      href="/about/terms-and-conditions"
                      variant="secondary"
                    >
                      Terms & Conditions
                    </SiteLink>{' '}
                    and{' '}
                    <SiteLink href="/about/privacy-policy" variant="secondary">
                      Privacy Policy
                    </SiteLink>
                  </>
                )}
              </SubmitStateText>
            </InputWrapper>
            <DesktopButton>
              <Button
                loading={isSubmitting}
                colorScheme="white"
                type="submit"
                variant="secondary"
                name="subscribe"
              >
                Sign Me Up
              </Button>
            </DesktopButton>
          </InputRow>
          <LowerRow>
            <MobileButton>
              <Button
                colorScheme="white"
                type="submit"
                variant="secondary"
                name="subscribe"
              >
                Sign Me Up
              </Button>
            </MobileButton>
          </LowerRow>
          <SocialIconsWrapper>
            <SocialIcons>
              <a
                href="https://twitter.com/wearesproutl"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Twitter"
              >
                <Twitter />
              </a>
              <a
                href="https://www.instagram.com/wearesproutl/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Instagram"
              >
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/company/sproutl"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="LinkedIn"
              >
                <LinkedIn />
              </a>
              <a
                href="https://www.youtube.com/channel/UCGgGNbp7wZ5Iuv5BKJG6jKg"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="LinkedIn"
              >
                <YouTube />
              </a>
            </SocialIcons>
            <TrustPilotIcon>
              <TrustPilotText>
                <TrustPilot />
              </TrustPilotText>
            </TrustPilotIcon>
          </SocialIconsWrapper>
        </Form>
      )}
    </Formik>
  );
}
