import React from 'react';

export default function GooglePay() {
  return (
    <svg
      width="60"
      height="24"
      viewBox="0 0 60 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.43 11.78v7h-2.2V1.5h5.82a5.22 5.22 0 0 1 2.04.37A5.26 5.26 0 0 1 35.83 3a4.82 4.82 0 0 1 1.57 3.64 4.83 4.83 0 0 1-.39 2.01 4.79 4.79 0 0 1-1.18 1.66 5.22 5.22 0 0 1-3.78 1.47h-3.62zm0-8.15v6.03h3.68a2.88 2.88 0 0 0 2.17-.9 2.97 2.97 0 0 0 0-4.22 2.86 2.86 0 0 0-2.17-.92h-3.68zm14.03 2.94c1.63 0 2.9.44 3.85 1.32.94.87 1.4 2.08 1.4 3.6v7.3h-2.1v-1.65h-.1a4.18 4.18 0 0 1-3.62 2.03A4.69 4.69 0 0 1 38.66 18a3.7 3.7 0 0 1-.98-1.3 3.73 3.73 0 0 1-.33-1.6c0-1.22.46-2.2 1.38-2.92a5.76 5.76 0 0 1 3.66-1.08c1.3 0 2.38.24 3.22.72v-.5a2.55 2.55 0 0 0-.9-1.97 3.1 3.1 0 0 0-2.12-.81c-1.23 0-2.2.52-2.92 1.57l-1.93-1.23a5.37 5.37 0 0 1 4.72-2.32zm-2.84 8.6a1.79 1.79 0 0 0 .73 1.44 2.66 2.66 0 0 0 1.7.58 3.48 3.48 0 0 0 2.48-1.04c.72-.69 1.09-1.5 1.09-2.44a4.46 4.46 0 0 0-2.87-.82c-.89 0-1.63.21-2.23.65-.6.44-.9.98-.9 1.62zm20.16-8.21L52.44 24h-2.27l2.73-5.97-4.83-11.07h2.4l3.48 8.5h.04l3.4-8.5h2.39z"
        fill="#5F6368"
      />
      <path
        d="M19.55 10.28a12 12 0 0 0-.17-2.02h-9.26v3.82h5.3a4.62 4.62 0 0 1-.67 1.71 4.57 4.57 0 0 1-1.29 1.3v2.49h3.17c1.85-1.73 2.92-4.28 2.92-7.3z"
        fill="#4285F4"
      />
      <path
        d="M10.12 19.98c2.65 0 4.88-.88 6.5-2.4l-3.16-2.48a5.88 5.88 0 0 1-8.86-3.15H1.34v2.56a9.89 9.89 0 0 0 3.62 3.99 9.74 9.74 0 0 0 5.16 1.48z"
        fill="#34A853"
      />
      <path
        d="M4.6 11.95a6.01 6.01 0 0 1 0-3.8V5.59H1.34a10.02 10.02 0 0 0 0 8.92l3.26-2.56z"
        fill="#FBBC04"
      />
      <path
        d="M10.12 4.05a5.3 5.3 0 0 1 3.76 1.49l2.8-2.84A9.38 9.38 0 0 0 10.13.12C8.3.12 6.5.63 4.96 1.6a9.89 9.89 0 0 0-3.62 3.99L4.6 8.15a5.88 5.88 0 0 1 5.51-4.1z"
        fill="#EA4335"
      />
      <title>Pay with Google Pay</title>
    </svg>
  );
}
