import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import { throwServerSideError } from 'utils/helpers';

import { ISubscribeEmailProps, TSubscribeEmailResponse } from './types';

/**
 * Subscribe user email address
 * @param {String} email - user's email
 * @async
 */
export default async function subscribeEmail({
  email,
}: ISubscribeEmailProps): Promise<TSubscribeEmailResponse> {
  throwServerSideError();

  const cleanedEmail = email.trim();

  if (!cleanedEmail) return Promise.reject();

  try {
    const result = await axios.post<TSubscribeEmailResponse>(
      '/api/iterable/subscribe',
      {
        email: cleanedEmail,
      },
    );
    return result.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Failed to subscribe user');
  }
}
